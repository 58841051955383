var pallasMaps = {
  map: null,

  locations: [],
  infoWindows: [],
  bounds: null,

  initMap: function () {
    //ie11 bugfix
    if (!NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach
    }

    pallasMaps.bounds = new google.maps.LatLngBounds()

    pallasMaps.map = new google.maps.Map(document.getElementById('map'), {
      center: {lat: 46.9, lng: 7.4},
      zoom: 8,
      styles: [
        {elementType: 'geometry', stylers: [{color: '#ffffff'}]},
        {elementType: 'labels.text.stroke', stylers: [{visibility: 'off'}]},
        {elementType: 'labels.text.fill', stylers: [{color: '#000000'}]},
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{color: '#000000'}],
        },
        {
          featureType: 'landscape',
          elementType: 'labels',
          stylers: [{'visibility': 'off'}],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{visibility: 'off'}],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{visibility: 'off'}],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{visibility: 'off'}],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{color: '#f2f2f2'}],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{color: '#f2f2f2'}],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{color: '#9ca5b3'}],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{color: '#f2f2f2'}],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{color: '#f2f2f2'}],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{color: '#9ca5b3'}],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{color: '#f2f2f2'}],
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{color: '#f2f2f2'}],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{color: '#c8d7d4'}],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{color: '#000000'}],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{color: '#17263c'}],
        },
      ],
    })

    pallasMaps.initLocations()
    pallasMaps.setEvents()
  },

  initLocations: function () {
    var mapElement = document.getElementById('map')
    pallasMaps.locations = JSON.parse(mapElement.dataset.locations)

    pallasMaps.locations.forEach(function (location) {
      pallasMaps.addMarker(location)
    })
  },

  addMarker: function (location) {
    if (!!location.latitude && !!location.longitude) {
      pallasMaps.addMarkerToMap(location, {lat: parseFloat(location.latitude), lng: parseFloat(location.longitude)})
      return
    }

    var geocoder = new google.maps.Geocoder()
    var address = location.street + ' ' + location.zip + ' ' + location.city

    geocoder.geocode({'address': address}, function (results, status) {
      if (status === 'OK') {
        pallasMaps.addMarkerToMap(location, results[0].geometry.location)
      } else if (status === 'OVER_QUERY_LIMIT') {
        window.setTimeout(function () {
          pallasMaps.addMarker(location)
        }, 100)
      } else {
        console.log('Geocode was not successful for the following reason: ' + status)
      }
    })
  },

  addMarkerToMap: function (location, position) {
    location.marker = new google.maps.Marker({
      position: position,
      map: null,
      icon: {
        url: '/typo3conf/ext/3m5_template/Resources/Public/Images/location.svg',
        scaledSize: new google.maps.Size(50, 50),
      },
    })

    pallasMaps.addInfoWindow(location)

    if (pallasMaps.locations.length === 1) {
      pallasMaps.map.setCenter(position)
      pallasMaps.map.setZoom(16)
    } else if (pallasMaps.bounds) {
      pallasMaps.bounds.extend(position)
      pallasMaps.map.setCenter(pallasMaps.bounds.getCenter())
    }

    pallasMaps.updateMarkers(false, -1)
  },

  addInfoWindow: function (location) {

    const infoWindow = new google.maps.InfoWindow({
      content: pallasMaps.getLocationInfoWindowContent(location),
    })
    pallasMaps.infoWindows.push(infoWindow)

    location.marker.addListener('click', function () {
      pallasMaps.infoWindows.forEach(function (iw) {
        iw.close()
      })
      infoWindow.open(pallasMaps.map, location.marker)
    })
  },

  getLocationInfoWindowContent: function (location) {
    const mapElement = document.getElementById('map')
    const locationLinks = document.querySelectorAll('[data-uid="' + location.uid + '"]')
    const formAnchorLink = locationLinks.length ? locationLinks[0].href : false

    return document.getElementById(`map-location-${location.uid}`)
  },

  setEvents: function () {
    //do not recenter map after user change
    window.setTimeout(function () {
      pallasMaps.map.addListener('drag', pallasMaps.disableBounds)
      pallasMaps.map.addListener('zoom_changed', pallasMaps.disableBounds)
    }, 100)
  },

  updateMarkers: function (isEvent, chosenFilter) {
    if (isEvent === true) {
      pallasMaps.infoWindows.forEach(function (iw) {
        iw.close()
      })
    }

    pallasMaps.locations.forEach(function (location) {
      if (location.parent) {
        return
      }
      if (location.hasOwnProperty('marker')) {
        if (pallasMaps.inGroup(location.groups, chosenFilter !== -1 ? [chosenFilter] : [])) {
          location.marker.setMap(pallasMaps.map)
          pallasMaps.updateChildLocationMarkers(chosenFilter, location.uid, true)
        } else {
          location.marker.setMap(null)
          pallasMaps.updateChildLocationMarkers(chosenFilter, location.uid, false)
        }
      }
    })
  },
  updateChildLocationMarkers: function (chosenFilter, parentLocationUid, isParentVisible) {
    pallasMaps.locations.forEach(function (location) {
      if (location.parent == parentLocationUid && location.hasOwnProperty('marker')) {
        if (pallasMaps.inGroup(location.groups, chosenFilter !== -1 ? [chosenFilter] : []) && !isParentVisible) {
          location.marker.setMap(pallasMaps.map)
        } else {
          location.marker.setMap(null)
        }
      }
    })
  },

  inGroup: function (locationGroups, activeGroups) {
    if (activeGroups.length === 0) {
      return true
    }
    let found = false
    locationGroups.forEach(function (locationGroupId) {
      activeGroups.forEach(function (activeGroupId) {
        if (locationGroupId === activeGroupId) {
          found = true
          return false
        }
      })
    })

    return found
  },
  disableBounds: function () {
    if (pallasMaps.bounds) {
      pallasMaps.bounds = null
    }
  },
}

function initMap() {
  pallasMaps.initMap()
}

// Hacky approach to make the function available globally to use in the Google Maps callback
// Nobody likes that approach... don't do this at home, kids
window.initMap = initMap
window.toggleMapFilter = toggleMapFilter

function lazy_maps() {
  var options = {
    rootMargin: '50px',
    threshold: 0,
  }

  var map = document.getElementById('map')

  var observer = new IntersectionObserver(
    function (entries, self) {
      var isIntersecting = typeof entries[0].isIntersecting === 'boolean' ? entries[0].isIntersecting : entries[0].intersectionRatio > 0

      if (isIntersecting) {
        var mapsScript = document.createElement('script')
        mapsScript.src = 'https://maps.googleapis.com/maps/api/js?callback=initMap&key=AIzaSyB2x-XUIZuvuSMHR6Hnj5T_wlZlO_8JjAo'
        document.getElementsByTagName('head')[0].appendChild(mapsScript)
        self.unobserve(map)
      }
    },
    options
  )

  observer.observe(map)
}

lazy_maps()

function toggleMapFilter(value) {
  pallasMaps.updateMarkers(true, parseInt(value))
}
